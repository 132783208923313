import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Header from "../components/Header"
import Footer from "../components/Footer"

export default function Privacy() {
  const { t } = useTranslation()

  return (
    <>
      {/* Privacy page meta tags */}
      <Helmet>
        <meta property="og:url" content="/" />
        <meta property="og:title" content={t("privacyMetaTitle")} />
        <meta property="og:description" content="TODO" />
        <meta property="og:image" content="TODO" />
        <meta property="og:image:alt" content="TODO" />
        <meta name="description" content="TODO" />
        <meta name="keywords" content="TODO" />

        <title>Privacy Page</title>
      </Helmet>
      <div className="bg-secondary">
        <Header></Header>
      </div>
      <div className="container py-4">
        <h1>{t("Politika privatnosti")}</h1>
        <p>
          Veb sajt www.mojtrening.rs, koji je u vlastištvu firme „Future Forward
          DOO“ iz Niša, prikuplja Vaše podatke o ličnosti u skladu sa važećim
          Zakonom o zaštiti podataka o ličnosti (“Sl. glasnik RS”, br. 87/2018,
          u daljem tekstu Zakon) kao i u vezi sa ostalim važećim zakonskim
          propisima i pravilima. „Future Forward DOO“ iz Niša fokusira se na
          sakupljanje minimalne količine informacija o Vama jedino sa ciljem
          realizacije usluga i unapređenja kvaliteta usluga na veb stranici
          www.mojtrening.rs (u daljem tekstu MojTrening).
        </p>
        <p>
          Podaci o ličnosti su svi podaci koji se neposredno ili posredno odnose
          ili mogu da se odnose na Vas kao fizičko lice. U slučaju da naš sajt
          sadrži linkove ka drugim internet stranicama, molimo Vas da se
          adekvatno upoznate sa politikama privatnosti koje se nalaze na tim
          internet stranicama i načinom na koji ta lica obrađuju podatke o Vama.
          Imajte u vidu da MojTrening nema nikakvu kontrolu nad sadržajem drugih
          internet stranica, te zbog toga ne možemo biti odgovorni za zaštitu
          Vaše privatnosti kada posećujete druge sajtove.
        </p>
        <ol>
          <li>
            <p>Način i pravni osnov prikupljanja podataka.</p>
          </li>
          <li>
            <p>Podaci koje prikupljamo</p>
            <ul>
              <li>
                <p>2.1. Gugl Analitika</p>
              </li>
              <li>
                <p>2.2. Društvene mreže</p>
              </li>
              <li>
                <p>2.3. Newsletter</p>
              </li>
            </ul>
          </li>
          <li>
            <p>Podaci o maloletnicima</p>
          </li>
          <li>
            <p>Mere bezbednosti</p>
          </li>
          <li>
            <p>Politika kolačića</p>
          </li>
          <li>
            <p>Pristup podacima o ličnosti</p>
          </li>
          <li>
            <p>Koliko dugo čuvamo Vaše podatke</p>
          </li>
          <li>
            <p>Ostvarivanje prava</p>
          </li>
          <li>
            <p>Stupanje na snagu i izmene Politike privatnosti</p>
          </li>
          <li>
            <p>Pravna pouka</p>
          </li>
        </ol>
        <h2 class="pt-4">1. Način i pravni osnov prikupljanja podataka</h2>
        <p class="pt-1">
          Sve informacije koje prikupljamo dobijamo direktno od Vas, kada
          naručite određene proizvode ili usluge, ili popunite određeni
          formular.
        </p>
        <p>
          Sve Vaše lične informacije tretiraju se kao strogo poverljive, a
          MojTrening ih čuva na adekvatan način uz primenu odgovarajućih
          bezbenosnih mera.
        </p>
        <p>
          U skladu sa pozitivnim zakonodavstvom, koje reguliše zaštitu ličnih
          podataka, možemo da prikljupamo i obrađujemo Vaše lične podatke po
          sledećim pravnim osnovima:
        </p>
        <ul>
          <li>
            <p>
              zaključenje i realizacija kupoprodajnog odnosa za proizvode i
              usluge iz sopstvene ponude;
            </p>
          </li>
          <li>
            <p>radi ispunjenja zakonskih i regulatorne obaveze;</p>
          </li>
          <li>
            <p>
              u svrhu ostvarenja sopstvenih legitimnih interesa zakonitog
              poslovanja, osim ukoliko su nad tim interesima pretežniji Vaši
              interesi ili osnovna prava i slobode, i
            </p>
          </li>
          <li>
            <p>
              u slučaju davanja dobovoljne saglasnost za određenu svrhu
              prikupljanja i obrade, pri čemu u svakom trenutku imate pravo da
              datu saglasnost povučete;
            </p>
          </li>
        </ul>
        <p>
          Obavezno je dostavljanje samo onih ličnih podataka čije prikupljamo je
          neophodno za ispunjenje zakonskih obaveza. Dostavljanje ličnih
          podataka po svim ostalim pravnim osnovima je dobrovoljno, ali
          napominjemo da u slučaju uskraćivanja ovih podatke, u određenim
          situacijama nećemo biti u mogućnosti da Vam pružimo određene usluge
          (kao što je, na primer, slanje promotivnih ponuda putem e-mail adrese,
          tzv. Newsletter).
        </p>
        <h2 class="pt-4">2. Podaci koje prikupljamo i njihovo korišćenje</h2>
        <p class="pt-1">
          Podaci koje prikupljamo odnose se na i zavise isključivo od: vrste
          usluge koju pružamo našim klijentima, namere klijenata da koriste naše
          usluge i od vrste ugovora koji sklapamo sa našim klijentima. Radi se o
          podacima bez kojih ne možemo da pružimo uslugu, a uključuju najmanje:
        </p>
        <p>– osnovne lične podatke (ime i prezime, datum rođenja);</p>
        <p>– osnovne kontakt podatke (adresa, broj telefona, e-mail adresa);</p>
        <p>
          – podatke vezane za korišćenje veb stranice&nbsp;
          <a href="https://mojtrening.rs/">www.mojtrening.rs</a> (korisničko ime
          i lozinka).
        </p>
        <p>
          MojTrening od korisnika može zahtevati naknadnu proveru identiteta
          kako bi informacije o nalozima na sajtu bile potpune i tačne, i kako
          bi se sprečile eventualne zloupotrebe. Ali MojTrening od Vas kao
          korisnika nikada neće putem elektronske pošte ili na drugi način
          tražiti Vašu šifru, podatke o kreditnoj kartici ili druge slične
          podatke. Ukoliko ste primili takav mejl – nemojte odgovarati na njega,
          ili nam prijavite takav slučaj na info@futureforward.rs.
        </p>
        <h2 class="pt-4">2.1 Gugl Analitika</h2>
        <p class="pt-1">
          Za potrebe prilagođavanja dizajna i stalnog poboljšavanja naših
          internet stranica, koristimo tzv. Gugl Analitiku – alat za veb
          analitiku koju pruža Google Inc. (“Google”). U tom kontekstu se
          kreiraju pseudonimni korisnički profili i koriste se kolačići.
          Kolačići generišu sledeće informacije o Vašem korišćenju ove internet
          stranice:
        </p>
        <p>– vrsta i verzija internet pretraživača,</p>
        <p>– operativni sistem koji se koristi,</p>
        <p>
          – internet stranica i/ili aplikacija sa koje se pristupa (referrer
          URL)
        </p>
        <p>– IP adresa,</p>
        <p>– datum i vreme pristupa.</p>
        <p>
          Podaci se obrađuju u svrhu istraživanja tržišta i prilagođavanja
          internet stranica. IP adrese su anonimizovane, tako da nije moguće
          utvrditi identitet. Ni pod kojim uslovima Vaša IP adresa neće biti
          povezana sa drugim podacima sa Gugla.
        </p>
        <h2 class="pt-4">2.2. Društvene mreže</h2>
        <p class="pt-1">
          Podatke na društvenim mrežama velikim delom obrađuje sam operater, i
          na taj deo MojTrening ima ograničen uticaj.
        </p>
        <p>
          Podatke prikupljene na društvenim mrežama obrađujemo u svrhu
          informisanja korisnika o aktuelnim promocijama i ponudama, a u cilju
          interakcije i unapređenja odnosa sa pratiocima.
        </p>
        <p>
          Podake prikupljene na društvenim mrežama, kao što su komentari, video
          snimci, slike, lajkovi, objavljuje operater. MojTrening ove podatke ne
          deli sa trećim licima i zadržava pravo brisanja nezakonitih sadržaja,
          komentara mržnje, eksplicitnog sadržaja i podataka koji predstavljaju
          krivično delo.
        </p>
        <h2 class="pt-4">2.3. Newsletter</h2>
        <p class="pt-1">
          Upisivanjem svoje e-mail adrese u okviru odeljka koji je predviđen za
          to na našem sajtu korisnik može da se prijavi za dobijanje newsletter
          pošte, kroz koju će biti obaveštavan o najnovijim akcijama, popustima
          i drugim promocijama u okviru našeg sajta. Tom prilikom koristiti se
          samo e-mail adresa korisnika i korisnik ima mogućnost prijave i odjave
          sa newsletter-a u bilo kom trenutku.
        </p>
        <h2 class="pt-4">3. Podaci o maloletnicima</h2>
        <p class="pt-1">
          MojTrening ne prikuplja, ne skladišti i ne obrađuje podatke o licima
          mlađim od 15 godina starosti.
        </p>
        <p>
          Budući da prema čl. 16 Zakona jedino maloletno lice koje je navršilo
          15 godina starosti može samostalno da daje pristanak za obradu
          podataka o svojoj ličnosti u korišćenju usluga informacionog društva,
          licima ispod navedenog uzrasta nije omogućeno da kreiranju korisnički
          nalog na&nbsp;
          <a href="http://www.mojtrening.rs">www.mojtrening.rs</a>. Ukoliko lice
          mlađe od 15 godina želi da se registruje na veb sajtu
          <a href="https://www.tunguzijashop.rs" target="_blank" rel="noreferrer">&nbsp;</a>
          <a href="http://www.mojtrening.rs">www.mojtrening.rs</a> potrebno je
          da nas roditelj koji vrši roditeljsko pravo, odnosno drugi zakonski
          zastupnik tog lica kontaktira na info@futureforward.rs u cilju davanja
          saglasnosti u skladu sa Zakonom.
        </p>
        <p>
          MojTrening zadržava pravo da odbije porudžbinu, naročito ako se radi o
          porudžbini veće vrednosti, i ne pristupi zaključenju i izvršenju
          ugovora o kupoprodaji ili pružanju usluga ako proceni da maloletnik
          nema potrebnu ugovarčku sposobnost koja se traži za zaključenje takvog
          ugovora, kao i pravo da zatraži odobrenje zakonskog zastupnika tog
          lica, u skladu sa odredbama čl. 56. Zakona o obligacionim odnosima i
          odredbama čl. 64 Porodičnog Zakona.
        </p>
        <h2 class="pt-4">4. Mere bezbednosti</h2>
        <p class="pt-1">
          Future Forward DOO Niš koristi sigurnosnu tehniku u vidu SSL standarda
          i enkripciju na veb sajtu&nbsp;
          <a href="http://www.mojtrening.rs">www.mojtrening.rs</a> kako bi se
          isti zaštitio od eventualnog gubitka, zloupotrebe ili izmene
          prikupljenih podataka.
        </p>
        <p>
          Specijalni softver šifrira i enkriptuje lične podatke koje unosite na
          MojTrening veb sajtu. Pored toga, Future Forward DOO Niš kontinuirano
          spovodi obuku svojih zaposlenih u pogledu zaštite podatak, a pristup
          podacima o ličnostima omogućen je samo onim zaposlonima čiji radni
          zadaci su u neposrednoj vezi sa obradom takvih padatak. Ostala lica
          nemaju ovlašćeni pristup.
        </p>
        <p>
          Future Forward DOO Niš ne može biti i nije odgovoran za neovlašćen
          pristup podacima od strane hakera ili drugih lica koja su na nelegalan
          i neligitiman način način pristupili veb sajtu&nbsp;
          <a href="http://www.mojtrening.rs">www.mojtrening.rs</a> ili bazama
          podataka na serveru na kome je hostovan veb sajt.
        </p>
        <p>
          “Pecanje” (Phishing) ili “mrežna krađa podataka” je prevara osmišljena
          na način da ukrade vaše lične podatke putem falsifikovanje veb
          stranice. Obično se takva lažna stranica nudi putem posebno
          pripremljene e-poruke. Ukoliko dobijete e-mail koji izgleda kao da je
          poslat od strane veb sajta MojTrening, a u kome se traže vaši lični
          podaci – nemojte odgovarati, ili nam prijavite takav slučaj na
          info@futureforwad.rs. MojTrening od Vas kao korisnika nikada neće
          putem elektronske pošte ili na drugi način tražiti Vašu šifru, podatke
          o kreditnoj kartici ili druge slične podatke.
        </p>
        <h2 class="pt-4">5. Politika kolačića</h2>
        <p class="pt-1">
          Samim korišćenjem veb sajta MojTrening saglasni ste po pitanju
          korišćenja kolačića u skladu sa našom politikom upotrebe kolačića.
        </p>
        <p>
          Kolačići ne sadrže viruse, trojane ili druge zlonamerne softvere.
          Kolačići su informacioni paketi koji se skladište na Vašem
          elektronskom uređaju prilikom posete sajtu, a njihova namena jeste
          pružanje podataka korisničkog iskustva koje korisnik ostvaruje
          prilikom posete sajtu.
        </p>
        <p>Kolačići se dele na:</p>
        <p>
          – obavezne – to su oni koji stranicu čine upotrebljivom omogućavajući
          neke od osnovnih funkcija, poput navigacije, i njima se teži
          poboljšanju korisničkog iskustva
        </p>
        <p>
          – trajne – oni koji ostaju duže u uređaju, odnosno pretraživaču, osim
          ukoliko ih korisnik manuelno ne ukloni.
        </p>
        <p>
          – statističke – služe za web analitiku, merenje posećenosti a takođe
          pružaju i uvid u način korisnikove komunikacije sa stranicom
        </p>
        <p>
          – marketinške – koriste se za praćenje posetilaca u okviru stranice i
          na osnovu prikupljenog se posetiocu, odnosno korisniku, prikazuju
          relevantni oglasi i podsticaj na učestvovanje.
        </p>
        <p>
          – kolačići treće strane – u pitanju su Fejsbuk kolačići I kolačići
          Gugl Analitike, koji služe za analitičke svrhe. Gugl Analitika kroz
          kolačiće prikuplja informacije o vašoj IP adresi i skladišti ih na
          svojim serverima. Vašu IP adresu Gugl ne povezuje sa drugim Gugl
          podacima.
        </p>
        <p>
          U svakom trenutku možete obrisati kolačiće ili podesiti pretraživač da
          ne prihvata kolačiće. Više o brisanju kolačića takođe možete pročitati
          <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en" target="_blank" rel="noreferrer">
            &nbsp;ovde
          </a>
          .
        </p>
        <h2 class="pt-4">6. Pristup podacima o ličnosti</h2>
        <p class="pt-1">
          MojTrening preduzima sve tehničke i organizacione mere kako bi se
          prikupljeni podaci o ličnosti obrađivali samo na način koji je u
          skladu sa navedenom svrhom i skladu sa Zakonom. Pored naših
          zaposlenih, u cilju pružanja određenih usluga, Vaše podatke možemo da
          ustupimo trećim licima – našim partnerima koji pružaju usluge u ime
          MojTrening-a. (na primer, IT partneri koji se bave održavanjem našeg
          veb sajta). U svim ovim slučajevima naš odnos sa eksternim partnerima
          i način zaštite i postupanja sa Vašim podacima o ličnosti obezbedili
          smo ugovorima u skladu sa Zakonom.
        </p>
        <p>
          Ukoliko je to neophodno pristup podacima o ličnosti će biti omogućen
          državnim organima i organima javne vlasti koji u svrhu sprovođenja
          zakonskih propisa imaju pravo pristupa podacima o ličnosti, u kom
          slučaju će biti blagovremeno obavešteni.
        </p>
        <p>
          MojTrening može povremeno objaviti informacije opšteg tipa trećim
          licima – na primer, broj posetilaca sajta koji su popunili neku online
          prijavu ili koju su učestvovali u nekoj nagradnoj igri ili drugoj
          promotivnoj aktivnosti, ali pritom nikada neće uključiti informacije
          pomoću kojih bi se mogli identifikovati pojedinci.
        </p>
        <h2 class="pt-4">7. Koliko dugo čuvamo Vaše podatke?</h2>
        <p class="pt-1">
          Lične podatke čuvamo onoliko koliko je to neophodno za ispunjenje gore
          navedenih svrha. Pri tome, vodimo računa o zakonskim obavezama čuvanja
          podataka o ličnosti.
        </p>
        <p>
          U slučaju kreiranja profila na našem sajtu ili prikupljanja podatka po
          osnovu saglasnosti, podaci o ličnosti će se obrađivati sve do brisanja
          korisničkog profila odnosno povlačenja date saglasnosti. Pri čemu
          opoziv saglasnosti ne utiče na dopuštenost obrade koja je vršena na
          osnovu saglasnosti pre opoziva.
        </p>
        <p>
          Podatke, prikupljene na osnovu saglasnosti, obrisaćemo i pre
          povlačenja saglasnosti, ako je ostvarena svrha za koju smo prikupljali
          podatke.
        </p>
        <p>
          Kada je osnov prikupljanja podataka zaključenje i izvršenje ugovora,
          podatke o realizovanim kupovinama i kupcima čuvamo koliko je to
          predviđeno poreskim propisima i propisima koji regulišu ugovor o
          prodaji.
        </p>
        <p>
          Nakon isteka predviđenih rokova, odnosno nakon povlačenja saglasnosti,
          ukoliko se podaci obrađuju po tom osnovu, svi podaci o kupcima će biti
          depersonalizovani odnosno obrisani ili uništeni.
        </p>
        <h2 class="pt-4">8. Ostvarivanje prava</h2>
        <p class="pt-1">
          U svakom trenutku možete nam se obratiti sa nekim od sledećih zahteva:
        </p>
        <ul>
          <li>
            <p>
              da Vam omogućimo pristup Vašim ličnim podacima, da odgovorimo za
              koje svrhe se koriste podaci koji se tiču Vas, kao i da pristupite
              takvim ličnim podacima, da znate svrhu obrade, kategorije Vaših
              ličnih podataka koje čuvamo, treće strane ili kategorije trećih
              strana sa kojima se Vaši lični podaci dele, period tokom kog
              zadržavamo podatke.
            </p>
          </li>
          <li>
            <p>
              da zahtevate ispravku netačnih, pogrešno unetih i/ili zastarelih
              podataka;
            </p>
          </li>
          <li>
            <p>
              da zatražite brisanje Vaših ličnih podataka ukoliko se obrada
              zasniva na Vašem pristanku, legitimnom interesu ili ukoliko podaci
              o ličnosti više nisu neophodni za ostvarivanje svrhe zbog koje su
              prikupljeni;
            </p>
          </li>
          <li>
            <p>
              da zahtevate ograničenje obrade Vaših podataka, ukoliko osporavate
              tačnost podataka o ličnosti u roku koji omogućava rukovaocu
              proveru tačnosti podatka o ličnosti, ukoliko smatrate da je obrada
              nezakonita, a ne želite da se podaci obrišu, ako ste istakli
              prigovor ili ako smatrate da aplikaciji MojTrening više nisu
              potrebni Vaši podaci;
            </p>
          </li>
          <li>
            <p>
              da podnesete prigovor na način na koji obrađujemo Vaše podatke,
              čak i u slučajevima kada nije sporno da podatke obrađujemo u
              skladu sa Zakonom;
            </p>
          </li>
          <li>
            <p>
              da uputite pritužbu Povereniku za zaštitu podataka o ličnosti, u
              skladu sa Zakonom.
            </p>
          </li>
        </ul>
        <p>
          MojTrening omogućava ostvarivanje prava vezanih za zaštitu podataka o
          ličnosti, i to postavljanjem zahteva:
        </p>
        <p>– putem elektronske pošte poslate na info@futureforward.rs</p>
        <p>– poštom na adresu: Vožda Karađorđa 14/5, 18000 Niš</p>
        <p>
          Nezavisno od kanala podnošenja, zahtev se podnosi lično sa napomenom
          „Kontakt osobi za zaštitu podataka o ličnosti“.
        </p>
        <p>
          Nadležni organ za zaštitu podataka o ličnosti je Poverenik za
          informacije od javnog značaja i zaštitu podataka o ličnosti Republike
          Srbije. Organu se možete obratiti na adresu Bulevar kralja Aleksandra
          15, 11000 Beograd, Republika Srbija, mejlom na office@poverenik.rs ili
          telefonom na +381 11 3408 900.
        </p>
        <h2 class="pt-4">9. Stupanje na snagu i izmene Politike privatnosti</h2>
        <p class="pt-1">
          Ova Politika privatnosti stupa na snagu dana 09.07.2021. godine.
        </p>
        <p>
          Politika privatnosti može biti izmenjena ili dopunjena, u zavisnosti
          od potreba i promene procesa na MojTrening-u, promene zakonskih okvira
          ili na zahtev nadležnog organa (Poverenika za zaštitu informaciju od
          javnog značaja i zaštitu podataka o ličnosti).Preporučuje se da
          korisnici redovno prate izmene i dopune Politike privatnosti, na
          <a href="https://www.tunguzijashop.rs" target="_blank" rel="noreferrer">&nbsp;</a>
          <a href="http://www.mojtrening.rs">www.mojtrening.rs</a>
        </p>
        <h2 class="pt-4">10. Pravna pouka</h2>
        <p class="pt-1">
          Odredbe Politike Privatnosti dopunjavaju i ne suspenduju zakonodavne
          odredbe iz oblasti zaštite podataka o ličnosti. U slučaju
          neusklađenosti odredbi Politike Privatnosti i zakonodavnih odredbi iz
          oblasti zaštite podatak o ličnosti primeniće se zakonodavne odredbe.
        </p>
      </div>

      <Footer></Footer>
    </>
  )
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
